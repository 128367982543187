@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;800&display=swap');

.MuiFormLabel-root-MuiInputLabel-root,
.MuiSelect-select.MuiOutlinedInput-input {
    font-family: 'Open Sans', sans-serif;
    text-align: left;
}

.MuiFormHelperText-root.MuiFormHelperText-sizeMedium {
    font-family: 'Open Sans', sans-serif;
}

.MuiInputLabel-root.MuiInputLabel-outlined {
    font-family: 'Open Sans', sans-serif;
}