div.card {
    background-color: #fff;
    border:1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
}

div.card .img {
    text-align: center;
    color: #ccc;
}

div.card .titulo {
    margin: 0;
    padding: 12px;
    text-transform: uppercase;
    text-align: center;
    font-size: 1.3rem;
    font-weight: 500;
}

div.card .descripcion {
    font-size: 0.8rem;
    color: #808080;
    font-weight: 300;
    padding: 10px;
    letter-spacing: 0.3px;
}

div.card .botones {
    margin: 0;
    padding: 5px 15px;
    text-align: center;
}