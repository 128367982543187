.MuiContainer-root.Headervista {
    margin: 0;
    padding: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ccc;
}

.Headervista .MuiGrid-root.MuiGrid-item.start {
    text-align: center;
    margin-top: 10px;
}

.Headervista .MuiGrid-root.MuiGrid-item.titulo {
    text-align: center;
    margin-top: 10px;
    font-size: 1.2rem;
    font-weight: 400;
    letter-spacing: 0.04rem;
}

.Headervista .MuiGrid-root.MuiGrid-item.end {
    text-align: center;
    margin-top: 10px;
}

@media screen and (min-width: 768px) {
    .Headervista .MuiGrid-root.MuiGrid-item.start {
        text-align: left;
        margin-top: 0;
    }
    
    .Headervista .MuiGrid-root.MuiGrid-item.titulo {
        text-align: center;        
        margin-top: 0;
    }
    
    .Headervista .MuiGrid-root.MuiGrid-item.end {
        text-align: right;
        margin-top: 0;
    }
}