@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

#error, #errorLeyenda {
    color: #9b0000;
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    max-width: 400px;
}


#error div.titulo {
    font-size: 1.5rem;
    font-weight: 700;
}
