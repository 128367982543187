#routesContainer{
    margin: 0;
    padding: 0;
    height: calc(100vh - 90px);
    margin-left: 251px;
    margin-top: 70px;
    overflow: hidden;
    overflow-y: auto;
    transition: all 0.3s;
}

#routesContainer.extendido{
    margin-left: 0;
}

#routesContainer #contenedor{
    margin: 25px;
    background-color: rgba(255,255,255,0.8);
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
}