table.niceTable{
    border: 1px solid #2E2E2E;
    font-size:0.8em;
}
table.niceTable thead,
table.niceTable tfoot{
    background-color: #2E2E2E;
    color: #f7f7f7;
}
table.niceTable thead td,
table.niceTable tfoot td{
    padding:5px;
    font-weight: 600;
    font-size: 0.9rem;
    letter-spacing: 0.5px;
}
table.niceTable tfoot tr:hover{
    background-color: #3c3c3c;
}
table.niceTable tbody tr td{
	padding:3px;
	vertical-align:middle;
	border:0;
}
table.niceTable tbody tr td{
	max-width: 100px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
table.niceTable tbody tr:nth-child(odd){
	background-color: #e8e8e8;
}
table.niceTable tbody tr:hover{
	background-color: #F2F5A9;
}
table.niceTable tbody tr.falta{
	background-color: #FCCDCD;
}
table.niceTable tbody tr.falta:hover{
	background-color: #FCA2A2;
}
table.niceTable tbody tr.selected{
	background:#bebebe;
	color:#545454;
}
table.niceTable tbody tr td.child{
	white-space: normal;
}
table.niceTable tbody tr td[type='img']{
	text-align: center;
}
table.niceTable tfoot tr th{
	padding-right:5px;
}