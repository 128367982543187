
.detalleRegistro .volver{
    text-align: left;
}
.detalleRegistro .titulo{
    text-align: center;
    font-size: 1.3rem;
    font-weight: 700;
    letter-spacing: 0.05rem;
    margin-top: 3px;
}
.detalleRegistro.header {
    border-bottom: 1px solid #c8c8c8;
    padding-top: 15px;
    padding-bottom: 15px;
}
.nuevoRegistro.footer .botones {
    text-align: center;
}
