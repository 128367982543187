@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

#sidebar {
    background: #F4F4F4;
    height: 100%;
    width: 250px;
    margin: 0;
    padding: 0;
    text-align: left;
    position: absolute;
    top: 0;
    left: 0;
    border-right: #ccc solid 1px;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
    overflow-y: auto;
    transition: all 0.3s;
}

#sidebar.closed {
    width: 0;
}


#sidebar ul {
    padding:0;
    width: 100%;
    height: auto;
    margin-top: 90px;
}

#sidebar ul li.sidebar-row {
    width: 100%;
    height: 50px;
    list-style-type: none;
    margin: 0;
    display: flex;
    flex-direction: row;
    color: #808080;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #ccc;
    -webkit-transition: background-color 100ms linear;
    -ms-transition: background-color 100ms linear;
    transition: background-color 100ms linear;
}

#sidebar ul a{
    text-decoration: none;
}

#sidebar ul li.sidebar-row:hover {
    cursor: pointer;
    background-color:#FFE600;
    color: #000;
}

#sidebar ul li.sidebar-row div.icono {
    flex: 30%;
    display: grid;
    place-items: center;
}

#sidebar ul li.sidebar-row div.titulo {
    flex: 70%;
    font-family: 'Open Sans', sans-serif;
}
