.caja1{
    padding: 25px 5px;
    margin-top:15px;
    margin-bottom:15px;
    text-align: left;
}
.nuevoRegistro .volver{
    text-align: left;
}
.nuevoRegistro .titulo{
    text-align: center;
    font-size: 1.3rem;
    font-weight: 700;
    letter-spacing: 0.05rem;
    margin-top: 3px;
}
.nuevoRegistro .MuiGrid-root.MuiGrid-item.botones {
    text-align: right;
}
.nuevoRegistro.header {
    border-bottom: 1px solid #c8c8c8;
    padding-top: 15px;
    padding-bottom: 15px;
}
.nuevoRegistro.footer {
    border-top: 1px solid #c8c8c8;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 15px;
}
.nuevoRegistro.footer .botones {
    text-align: center;
}

@media screen and (min-width: 768px) {
    .nuevoRegistro.footer .botones {
        text-align: right;
    }
}